import formatDate from '@helpers/methods/formatDate';

const formatCreatedAt = (array = []) => {
  const isArrayInvalid = !Array.isArray(array);
  if (isArrayInvalid) return [];

  const isArrayEmpty = array.length <= 0;
  if (isArrayEmpty) return [];

  return array.map((item) => ({
    ...item,
    created_at: formatDate(item?.created_at),
  }));
};

export default formatCreatedAt;
