import sanitizeArray from '../utils/sanitizeArray';
import formatCreatedAt from '@helpers/methods/formatCreatedAt';
import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';
import selectAuthor from '../utils/selectAuthor';
import selectContentImage from '../utils/selectContentImage';

const generateCareerAdviceCardsProps = (careerAdvices) => {
  const addAuthor = (destinationContent, sourceContent) => {
    return {
      ...selectAuthor(destinationContent, sourceContent),
    };
  };

  const careerAdviceCardsProps = careerAdvices.map((careerAdvice) => {
    let careerAdviceCardProps = careerAdvice;
    careerAdviceCardProps = addAuthor(careerAdviceCardProps, careerAdvice);
    careerAdviceCardProps = selectContentImage(
      careerAdviceCardProps,
      careerAdvice
    );
    return careerAdviceCardProps;
  });

  return careerAdviceCardsProps;
};

const getCareerAdviceCardsProps = (careerAdvices) => {
  let careerAdviceCardsProps = sanitizeArray(careerAdvices);
  careerAdviceCardsProps = formatCreatedAt(careerAdviceCardsProps);
  careerAdviceCardsProps = sortByPublishedDate(careerAdviceCardsProps);

  careerAdviceCardsProps = generateCareerAdviceCardsProps(
    careerAdviceCardsProps
  );

  return careerAdviceCardsProps;
};

export default getCareerAdviceCardsProps;
