import React, { useState } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import useDidMountEffect from '@helpers/hooks/useDidMountEffect';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import AutoComplete from 'antd/lib/auto-complete';
import Input from 'antd/lib/input';

const DEFAULT_PLACEHOLDER = '';
const DEFAULT_SEARCHQUERY = '';
const STRING_ERROR = 'Search Query is not a string.';

const FlexSearchBar = ({
  onQuery = () => console.warn('onQuery Parameter Not Used.'),
  defaultValue = DEFAULT_SEARCHQUERY,
  searchPlaceholder = DEFAULT_PLACEHOLDER,
  flexSearchOptions = { index: '', store: '' },
  searchSuggestions = [],
}) => {
  const [query, setQuery] = useState(defaultValue);
  const [isSuggestDropdownOpen, setIsSuggestDropdownOpen] = useState(false);
  const [suggestOptions, setSuggestOptions] = useState({
    cache: [],
    suggestions: [],
  });
  const { index: searchIndex, store: searchStore } = flexSearchOptions;
  const flexResults = useFlexSearch(query, searchIndex, searchStore);

  const extractIds = (items = [{ id: 0 }]) => items.map((item) => item.id);
  const response = { query, results: extractIds(flexResults) };

  useDidMountEffect(() => {
    onQuery(response);
  }, [JSON.stringify(response)]);

  const handleInputSearch = (searchQuery = DEFAULT_SEARCHQUERY) => {
    setIsSuggestDropdownOpen(false);
    const isString = typeof searchQuery === 'string';
    if (!isString) return console.error(STRING_ERROR);
    setQuery(searchQuery?.trim?.());
    setSuggestOptions({
      ...suggestOptions,
      suggestions: [],
    });
  };

  const handleInputChange = (event) => {
    const value = event?.target?.value?.trim?.() ?? '';
    const hasPrevQuery = !!query;
    const hasNoValue = !value;
    const shouldClearSearch = hasPrevQuery && hasNoValue;
    if (shouldClearSearch) {
      setQuery('');
    }
  };

  const handleAutoCompleteChange = (onChangeQuery = DEFAULT_SEARCHQUERY) => {
    setIsSuggestDropdownOpen(true);
    const isString = typeof onChangeQuery === 'string';
    if (!isString) return console.error(STRING_ERROR);

    const minQueryLength = 3;
    const shouldNotSuggestWords =
      onChangeQuery?.trim?.().length < minQueryLength;
    if (shouldNotSuggestWords) {
      setSuggestOptions({
        ...suggestOptions,
        cache: [],
        suggestions: [],
      });
      return;
    }

    const getSuggestedWords = (wordIndex = ['']) => {
      return wordIndex.filter((word) => {
        const lowerCaseWord = word?.toLowerCase();
        const trimmedLowerCaseQuery = onChangeQuery?.trim?.().toLowerCase();
        const hasSearchedWordMatchedAnSuggestionIndexWord = lowerCaseWord?.includes(
          trimmedLowerCaseQuery
        );

        return hasSearchedWordMatchedAnSuggestionIndexWord;
      });
    };

    const moldToOptions = (suggestedWordList = [], searchedWord = '') => {
      const renderHighlightedTexts = (
        textToHighlight = '',
        searchWord = ''
      ) => {
        if (!searchWord) return <span>{textToHighlight}</span>;

        let index = textToHighlight
          .toLowerCase()
          .indexOf(searchWord?.trim?.().toLowerCase());
        if (index <= -1) return <span>{textToHighlight}</span>;
        let length = searchWord?.trim?.().length;
        let prefix = textToHighlight.substring(0, index);
        let suffix = textToHighlight.substring(index + length);
        let match = textToHighlight.substring(index, index + length);

        return (
          <span>
            {prefix}
            <span className="text-highlight">{match}</span>
            {suffix}
          </span>
        );
      };

      const isSuggestedWordsEmpty = suggestedWordList.length === 0;
      if (isSuggestedWordsEmpty) return suggestedWordList;
      return suggestedWordList.map((suggestedWord) => ({
        label: renderHighlightedTexts(suggestedWord, searchedWord),
        value: suggestedWord,
      }));
    };

    const limitOptionsLength = (suggestedOptions = [], limit = 0) => {
      const isShorterThanLimit = suggestedOptions.length >= limit;
      if (isShorterThanLimit) return suggestedOptions;
      return suggestedOptions.slice(0, 7);
    };

    const suggestedWords = getSuggestedWords(searchSuggestions);
    const autoCompleteOptions = moldToOptions(suggestedWords, onChangeQuery);
    const limitedAutoCompleteOptions = limitOptionsLength(
      autoCompleteOptions,
      7
    );

    setSuggestOptions({
      ...suggestOptions,
      cache: limitedAutoCompleteOptions,
      suggestions: limitedAutoCompleteOptions,
    });
  };

  const handleAutoCompleteBlur = () => {
    setSuggestOptions({
      ...suggestOptions,
      suggestions: [],
    });
  };

  const handleAutoCompleteFocus = () => {
    setSuggestOptions({
      ...suggestOptions,
      suggestions: suggestOptions?.cache,
    });
  };

  const handleAutoCompleteSelect = () => {
    setSuggestOptions({
      ...suggestOptions,
      suggestions: [],
    });
  };

  const handleAutoCompleteClear = () => {
    setSuggestOptions({
      ...suggestOptions,
      cache: [],
      suggestions: [],
    });
  };

  return (
    <div className="search-input-container">
      <AutoComplete
        open={isSuggestDropdownOpen}
        defaultValue={defaultValue}
        dropdownClassName={'search-suggestions-dropdown'}
        options={suggestOptions?.suggestions}
        onChange={handleAutoCompleteChange}
        onFocus={handleAutoCompleteFocus}
        onBlur={handleAutoCompleteBlur}
        onSelect={handleAutoCompleteSelect}
        onClear={handleAutoCompleteClear}
      >
        <Input.Search
          className={'input-search-desktop'}
          prefix={<SearchOutlined />}
          placeholder={searchPlaceholder}
          enterButton={<SearchOutlined />}
          onSearch={handleInputSearch}
          onChange={handleInputChange}
          allowClear={true}
        />
      </AutoComplete>
    </div>
  );
};

export default FlexSearchBar;
